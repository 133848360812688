<template>
    <div>
        <Modal :title="title" v-model="isShowModal">
            <template v-slot:modal-content>
                <div class="alert-word">
                    {{ message }}
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="turnOffModal"
                />
                <Button
                    buttonWord="確定"
                    buttonStyle="blue"
                    @click="closingCommission"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { closingCommissionAPI } from '@/assets/javascripts/api'

export default {
    name: 'AdminClosingAlert',
    emits: ['update:modelValue', 'update:commissionStatus', 'update:success'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean
        },
        success: {
            type: Boolean
        },
        commissionStatus: {
            type: String
        },
        mType: {
            type: String
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        },
        closingCommission: async function () {
            this.$setGaEvent('commissionClose', 'click-Button')
            this.$showLoading()
            try {
                await closingCommissionAPI(this.mType)
                this.commissionStatusValue = 'closed'
                this.$emit('update:success', true)
            } catch (error) {
                // eslint-disable-next-line
                this.$showErrorMessage(this.errorMessage)
            } finally {
                this.$hideLoading()
                this.turnOffModal()
                window.scrollTo(0, 0)
            }
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        commissionStatusValue: {
            get() {
                return this.commissionStatus
            },
            set(val) {
                this.$emit('update:commissionStatus', val)
            }
        }
    },
    data() {
        return {
            title: '確定要資料暫停更新嗎？',
            message:
                '請注意，資料暫停更新後，將以最後更新日期的資料試跑佣金報表。',
            errorMessage: '將資料暫停更新時出現錯誤..建議您重新整理頁面'
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-word {
    font-size: 14px;
    color: $secondary-grey;
}
</style>
